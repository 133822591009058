
import React, { Fragment,Component,useState,useEffect } from 'react';
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import swal from 'sweetalert';
import axios from 'axios';
import { decode } from "html-entities";
import { useParams,Link  } from "react-router-dom";
import HeaderSeven from '../component/layout/header-7';

function CourseDetails() {
   
    const { slug } = useParams();
    useEffect(() => {
            const getData = async () =>{
                const course = await axios.get(global.config.URL.api.public + 'course-details/' + slug);
                setCourse(course.data.courseDetails);
            }
        getData(); 
        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
      
    },[slug]);

   
    async function enroll () {
        if(!global.config.Token){
              window.location.href ='/login';
        }
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const enrolled = await axios.get(global.config.URL.api.apply + 'add-to-cart/' + course.id);

        if(enrolled.data.success)
        {
            
        swal({
            title: "Success!",
            text: `${enrolled.data.message}`,
            icon: "success",
            buttons: {cancel:"OK",text:"GO TO CART"},
          })
          .then((yes) => {
            if (yes) {
                  window.location.href ='/my-cart';
            } else {
              
            }
          });
           
        }else{
          
            swal({
                title: "Warning!",
                text: `${enrolled.data.message}`,
                icon: "warning",
                buttons: {cancel:"OK",text:"GO TO CART"},
              })
              .then((yes) => {
                if (yes) {
                      window.location.href ='/my-cart';
                } else {
                  
                }
              });
        }
    }
    const [course, setCourse]= useState([]);
  
    return (
        <Fragment>
            <HeaderSeven />
            <div className="pageheader-section style-2">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src={course.detail ? global.config.URL.img.public + course.detail.img : ''}  alt="detail Image" className="w-100" />
                            
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            <div className="course-category">
                         
                            <p>{course.domain ? course.domain.name : ''}</p>
                            </div>
                            <h2 className="phs-title">{course.name}</h2>
                            <p className="phs-desc text-justify">{course.description}</p>
                            <div className="phs-thumb mb-5">
                                <img src={process.env.PUBLIC_URL +"/favicon.png"} alt="niais" />
                                <span>NIAIS</span>
                                <a href={course.detail ? global.config.URL.img.public + course.detail.tags : '' } target='_blank' rel="noreferrer" className="btn btn-success btn-sm text-light"><span>{'Download Course Outline'}</span></a>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: course.detail
                                                    ? decode(course.detail.detail)
                                                    : ""
                                                }}
                                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                            <div className="course-side-detail">
            <div className="csd-title">
                <div className="csdt-left">
                    <h4 className="mb-0"><sup>fee-</sup><del>{course.fee + course.fee }</del><span className='text-light'> {course.fee }</span></h4>
                </div>
                <div className="csdt-right">
                    <p className="mb-0"><i className="icofont-clock-time"></i>{course.detail ? course.detail.duration:""}</p>
                </div>
            </div>
            <div className="csd-content">
            <div className="csdc-lists">
                    <ul className="lab-ul">
                      
                            <li >
                                <div className="csdc-left"><i className="icofont-ui-alarm"></i>Course level</div>
                                <div className="csdc-right"></div>
                                <div className="csdc-right">{course.detail ? course.detail.skill_level:""}</div>

                            </li>
                            <li >
                                <div className="csdc-left"><i className="icofont-book-alt"></i>Course Duration</div>
                                <div className="csdc-right">{course.detail ? course.detail.duration:""}</div>
                            </li>
                            <li >
                                <div className="csdc-left"><i className="icofont-signal"></i>Class</div>
                                <div className="csdc-right">Onsite</div>
                            </li>
                            <li >
                                <div className="csdc-left"><i className="icofont-certificate"></i>Certificate</div>
                                <div className="csdc-right">Yes</div>
                            </li>
                    </ul>
                </div>
               
              
                <div className="course-enroll">
                    <Link to="/signup" className="lab-btn"><span>{'Enroll Now'}</span></Link>
                </div>
            </div>
        </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </Fragment>
    )
}
export default CourseDetails;