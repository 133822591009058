import { useState } from "react";
import { Link, NavLink } from "react-router-dom";


const phoneNumber = "+92308 244 4618";
const address = "Arfa Tower Lahore,Punjab";

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/niais.org',
        className: 'facebook',
    },
    {
        iconName: 'icofont-youtube',
        siteLink: 'https://www.youtube.com/channel/UCLgy1-QRXkFsZedZMjPBREA',
        className: 'pinterest',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/niais/',
        className: 'linkedin',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/niais_official/',
        className: 'instagram',
    },
   
]

const HeaderSeven = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});


    return (
        <header className={`header-section style-7 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <ul className="lab-ul left">
                            <li>
                                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
                            </li>
                            <li>
                                <i className="icofont-location-pin"></i> {address}
                            </li>
                        </ul>
                        <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <Link to="/"><img src="/assets/images/logo/01.png" alt="logo" /></Link>
                        </div>
                        <div className="menu-area">
                        <div className="menu">
                                    <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                        <li><NavLink to="/">Home</NavLink></li>
                                        <li><NavLink to="/course">Courses</NavLink></li>
                                        <li><NavLink to="https://onsitelms.niais.org/">LMS</NavLink></li>
                                        <li><NavLink to="/contact">Contact</NavLink></li>
                                        {global.config.Login ?
                                      <li><NavLink to="/my-account" ><i className="icofont-user"></i> <span>MY Account</span> </NavLink></li>
                                        :
                                        <>
                                        <li> <NavLink to="/login" ><i className="icofont-user"></i> <span>LOG IN</span> </NavLink></li>
                                        <li>  <NavLink to="/signup" className="bg-primary text-light" ><i className="icofont-users text-light"></i> <span>APPLY NOW</span> </NavLink></li>
                                   </>
                                        }
                                    </ul>
                                </div>
                              
                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-md-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default HeaderSeven;