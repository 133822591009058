import { Component,  useEffect, useState,Fragment } from "react";

import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import HeaderSeven from "../component/layout/header-7";
import FooterTwo from "../component/layout/footer-2";
import axios from 'axios';


const title = "NIAIS | ONSITE | LOGIN";
const socialTitle = "Login With Social Media";
const btnText = "Login";




const LoginPage = () => {
    const [show,setShow] = useState(false);
    useEffect(() => {
        const form = document.getElementById("form_login");
        const user = document.getElementById("login_user");
        const password = document.getElementById("login_password");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const userValue = user.value.trim();
            const passwordValue = password.value.trim();

            if (userValue === "") {
                setError(user, "User can't be blank");
            } else {
                setSuccess(user);
            }

            if (passwordValue === "") {
                setError(password, "Password can't be blank");
            } else {
                setSuccess(password);
            }

            if(user.value.trim() && password.value.trim())
            {
                login();
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".login_input-msg");
            formControl.className = "form-group text-start text-danger";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-group success";
           
        }
        const login = async () =>{
             const res = await axios.post(global.config.URL.api.user + 'login',{
                email: user.value.trim(),
                password: password.value.trim(),
              });
              if(res && res.data.success)
              {
                localStorage.setItem("authUser", JSON.stringify(res.data));
               if(res.data.profile)
               {
                window.location.href = '/my-account';
               }else{
                window.location.href ="/profile-signup";
               }
               
              }else{
               const message = res.data.message;
               if(message.email)
               {
                setError(user,  message.email);
               }
               if(message.password)
               {
                setError(password,  message.password);
               }
               
         }
        }   
    },[]);

    return (
        <Fragment>
            <HeaderSeven />
            {/* <PageHeader title={'Login Page'} curPage={'Login'} /> */}
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form" id="form_login">
                            <div className="form-group">
                            <input type="text" placeholder="Email" id="login_user" />
                                            <span className="login_input-msg"></span>
                            </div>
                            <div className="form-group">
                            <input type={show ? 'text' : 'password'} placeholder="password" id="login_password" />
                                            <span className="login_input-msg"></span>
                                           {show === true ? <i class="icofont-eye  float-end" aria-hidden="true" onClick={() => setShow(false)}></i> : <i class="icofont-eye-blocked float-end" onClick={() => setShow(true)} aria-hidden="true"></i> } 
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                    <div className="checkgroup">
                                        <input type="checkbox" name="remember" id="remember" />
                                        <label htmlFor="remember">Remember Me</label>
                                    </div>
                                    {/* <Link to="/forgetpass">Forget Password?</Link> */}
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <button className="d-block lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Don’t Have any Account?  <Link to="/signup">Sign Up</Link></span>
                            
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </Fragment>
    );
}
 
export default LoginPage;