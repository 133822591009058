import React, { Component, Fragment,useState,useEffect  } from "react";


import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import { InfinitySpin } from 'react-loader-spinner'
import { useParams } from "react-router-dom";
import JsPDF from 'jspdf';
import moment from 'moment';
import "./invoice.css"
import axios from 'axios';
import "../component/helper/helper";
import swal from 'sweetalert';
import Skill from "../component/section/skill";

import {  Container, Row, Col, Tab, Nav } from "react-bootstrap";
import HeaderSeven from "../component/layout/header-7";
function GenerateInvoice() {
    const [invoice, setInvoice]= useState([]);  
    const [batch, setBatch]= useState([]);  

    const { number } = useParams();
    useEffect(() => {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
            const getData = async () =>{
                const course = await axios.get(global.config.URL.api.apply + 'generate-invoice/' + number);
                setInvoice(course.data.invoice);
                setBatch(course.data.batch);
            }
        getData(); 
    
    },[number]);
   
   const generatePDF = () => {
       

        const report = new JsPDF('l', 'pt', [1200, 820]);
        report.html(document.querySelector('#pdf')).then(() => {
            report.save(`niais-invoice(${invoice.invoice_no}).pdf`);
        });
    }
  
    
    return (
        <Fragment>
            <HeaderSeven />
            <PageHeader title={'Payment Modes'} curPage={'Invoice Page'} />
           
            { invoice ?


<Skill amount={invoice.amount} />



: 
<h4 className='bg-danger text-light text-center'>  <i className="lab la-bitbucket"></i> Invoice Not Found</h4>


}   
                   
            <FooterTwo />
        </Fragment>

)
}


export default GenerateInvoice