
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import "../helper/helper";
import axios from 'axios';

const subTitle = "NIAIS";
const title = "We Offer Courses";

function CourseThree() {
    useEffect(() => {
        const getData = async () => {
            const domains = await axios.get(global.config.URL.api.public + 'domain-courses');
            setDomains(domains.data.domains[0]);
            console.log(domains.data.domains[0],'domains.data')
        }
        getData();
    }, []);

    const [domains, setDomains] = useState([])




    return (
        <div className="course-section style-4 padding-tb">
        <div className="container">
            <div className="section-header text-center">
                <span className="subtitle text-warning">{subTitle}</span>
                <h2 className="title text-light">{title}</h2>
            </div>
            <div className="section-wrapper">
                <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1">
                {domains.courses ? domains.courses.map((val, i) => (
                        <div className="col" key={i}>
                            <div className="course-item style-5">
                                <div className="course-inner">
                                    <div className="course-thumb">
                                        <Link to="#!" className="d-block"><img src={global.config.URL.img.public + val.image} alt={`Card Img`} /></Link>
                                    </div>
                                    <div className="course-content">
                                        <Link to="#!"><h5>{val.name}</h5></Link>
                                        <div className="course-category">
     
                                                  <div className="course-cate">
                                                       <a href="/course">Apply For Online</a>
                                                     </div>
                                                   <div className="course-cate">
                                                   <a href="https://onsite.niais.org">Apply For Onsite</a>
                                                  </div>
                                            </div>
                                        <span className="course-cate">{val.cate}</span>
                                        <div className="course-footer">
                                            <Rating />
                                            <div className="course-price">NIAIS</div>
                                             
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     )): ""}
                </div>
            </div>
        </div>
    </div>
        // <div className="course-section padding-tb">
        //     <div className="container">
        //         <div className="section-header text-center">
        //             <span className="subtitle">{subTitle}</span>
        //             <h2 className="title">{title}</h2>
        //         </div>
        //         <div className="section-wrapper">
        //         <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
        //                     {domains.courses ? domains.courses.map((val, i) => (
        //                         <div className="col" key={i}>
        //                             <div className="course-item">
        //                                 <div className="course-inner">
        //                                     <div className="course-thumb">
        //                                         <img src={global.config.URL.img.public + val.image} alt={`Card Img`} />
        //                                     </div>
        //                                     <div className="course-content">
                                                
                                                
        //                                         <div className="course-category">
        //                                             <div className="course-cate">
        //                                                 <a href="/courses">Apply For Online</a>
        //                                             </div>
        //                                              <div className="course-cate">
        //                                                 <a href="https://onsite.niais.org">Apply For Onsite</a>
        //                                             </div>
        //                                         </div>
                                                
                                                
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )): ""}
        //                 </div>
        //         </div>
        //     </div>
        // </div>
    );
}
 
export default CourseThree;