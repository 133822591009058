// Base URL LOCAL
// const base_api = "http://127.0.0.1:8000/api/";
// const base_img = "http://127.0.0.1:8000/";
const user = JSON.parse(localStorage.getItem("authUser"));

// Base URL Live
const base_api = "https://admin-onsite.niais.org/api/";
const base_img = "https://admin-onsite.niais.org/";
const base_onsite_api = "https://admin-onsite.niais.org/api/";
const base_onsite_img = "https://admin-onsite.niais.org/";

module.exports = global.config = {
    URL: {
        api: {
            public: base_api+"public/",
            user: base_api+"user/",
            apply: base_api+"apply/",
            student: base_api+"student/",
        },
        img: {
            public: base_img+"storage/",
        }
        ,
        onsite_img: {
            public: base_onsite_img+"storage/",
        },
        onsite_api: {
            public: base_onsite_api,
        }
    },
    Token : user ? user.token : "",
    Login : user ? user.login : false,
};