import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import { InfinitySpin } from 'react-loader-spinner'
import axios from 'axios';
import "../component/helper/helper";
import swal from 'sweetalert';

import {  Container, Row, Col, Tab, Nav } from "react-bootstrap";
import HeaderSeven from "../component/layout/header-7";
class MyAccount extends Component {

    constructor(props){
        super(props);
        this.state = {
            rollno:'',
            name:'',
            email:'',
            mob:'',
            whatsapp:'',
            cnic:'',
            qualification:'',
            city:'',
            address:''
        }
        this.logout = this.logout.bind(this);
        this.myProfile = this.myProfile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.setError = this.setError.bind(this);
        
    }
   async updateProfile()
    {
        if(!global.config.Token){
            this.props.history.push('/login');
        }
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;        
        const res = await axios.post(global.config.URL.api.student + 'update-profile',{
            data : this.state
          },);
          if(res && res.data.success)
          {
            if(res.data.exception.ex){
                Object.entries(res.data.exception).map(([key,value])=>{
                    if(value)
                    {
                        const input = document.getElementById(key);
                        if (input){
                            return (
                                this.setError(input,`${key} has already been taken`)
                            );
                        }
                    }
                    const input = document.getElementById(key);
                    if (input){
                        return (input.parentElement.className = "form-control")
                    }
                    return null;
                    
                })
            }else{
               
                swal({
                    title: "Updated!",
                    text: "Your profile has been successfully Updated!",
                    icon: "success",
                    button: "OK!",
                  })
                  const elements = Array.from(document.getElementsByClassName("error"));
                  elements.map(element=>{
                   return element.className = "form-control success" ;
                })
            }
           }else{
           
           alert(res.data.message);
           }
        
    }
     handleChange(evt) {
        const value = evt.target.value;
        this.setState({
          ...this.state,
          [evt.target.name]: value
        },function(){});
      }
      setError(input, message) {
        const formControl = input.parentElement;
        input.focus();
        const errorMsg = formControl.querySelector(".registration_input-msg");
        formControl.className = "form-control text-left error";
        errorMsg.innerText = message;
    }
    componentDidMount()
    {
        this.myProfile();
    }

    async myProfile()
    {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const user = await axios.get(global.config.URL.api.student + 'my-profile');
        if(user.data.success)
        {
            this.setState({
                name:user.data.profile.name,
                rollno:user.data.profile.student_profile.roll_number,
                email:user.data.profile.email,
                mob:user.data.profile.mob,
                whatsapp:user.data.profile.student_profile.whatsapp,
                cnic:user.data.profile.student_profile.cnic,
                qualification:user.data.profile.student_profile.qualification.name,
                city:user.data.profile.student_profile.city.name,
                address:user.data.profile.student_profile.address
            },function(){});
        }
    }

    logout()
    {
        localStorage.clear();
        window.location.href = '/';
    }
    render() {
    return (
        <Fragment>
            <HeaderSeven />
            <PageHeader title={'My Account'} curPage={'Account Page'} />
            <Container>
                        <Tab.Container defaultActiveKey="profile">
                            <Row>
                                <Col lg="3" md="4" className="card">
                                    <Nav className="flex-column">
                                        <Nav.Item className="card mt-3 ">
                                            <Nav.Link eventKey="profile"><i className="icofont-user la-lg"></i>My Profile</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="card  mt-3">
                                            <Nav.Link><i className="icofont-shopping-cart la-lg"></i><Link to={'/my-cart'}>My cart</Link></Nav.Link>
                                        </Nav.Item  >
                                        <Nav.Item  className="card  mt-3" > 
                                            <Nav.Link><i className="icofont-paper la-lg"></i><Link to={'/my-invoices'}>My Invoices</Link></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item  className="card  mt-3">
                                            <Nav.Link onClick={() => this.logout()} ><i className="icofont-sign-out la-lg"></i>Logout</Nav.Link>
                                        </Nav.Item>
                                       
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="profile">
                                        <Row>
                            <Col md="12">
                          
                                <div className="registration-box p-5 card">
                                    
                                    <div id="form_registration card-body p-3" className="form row">
                                    <div className="col-md-6">
                                    <p className="form-group">
                                            <label htmlFor="rollno">Roll Number</label>
                                            <input type="text" readOnly value={this.state.rollno} id="rollno" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                    <div className="col-md-6">
                                    <p className="form-group">
                                            <label htmlFor="registration_name">Name</label>
                                            <input type="text" placeholder="Enter Name" name='name' onChange={this.handleChange} value={this.state.name} id="registration_name" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-6">
                                        <p className="form-group">
                                            <label htmlFor="mobile">Mobile</label>
                                            <input type="text" placeholder="Enter Mobile"  name='mob' onChange={this.handleChange} value={this.state.mob} id="mobile" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-6">
                                        <p className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" placeholder="Enter Email"  name='email' onChange={this.handleChange} value={this.state.email} id="email" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-6">
                                        <p className="form-group">
                                            <label htmlFor="cnic">CNIC</label>
                                            <input type="text" placeholder="Enter CNIC"  name='cnic' onChange={this.handleChange} value={this.state.cnic} id="cnic" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-6">
                                        <p className="form-group" id="qua">
                                            <label htmlFor="registration_qualification">Qualification</label>
                                            {/* <SelectBox1
                                                options={this.Allqualifications}
                                                name={"qua"}
                                                id={"select1"}
                                                onChange={this.handleChange}
                                                /> */}
                                            <input type="text" placeholder="Enter Qualification" value={this.state.qualification} readOnly id="registration_qualification" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-6">
                                        <p className="form-group">
                                            <label htmlFor="whatsapp">Whatsapp Number</label>
                                            <input type="text" placeholder="Enter Whatsapp Number"  name='whatsapp' onChange={this.handleChange} value={this.state.whatsapp}  id="whatsapp" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-6">
                                        <p className="form-group"  id="city">
                                            <label htmlFor="registration_city">City</label>
                                            {/* <SelectBox2
                                                options={this.Allcities}
                                                name={"city"}
                                                id={"select2"}
                                                onChange={this.handleChange}
                                                /> */}
                                            <input type="text" placeholder=""  value={this.state.city} readOnly id="registration_city" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <div className="col-md-12">
                                        <p className="form-group">
                                            <label htmlFor="registration_address">Address</label>
                                            <input type="text" placeholder="Enter your address"  name='address' onChange={this.handleChange}  value={this.state.address}  id="registration_address" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        </div>
                                        <button className="btn btn-success m-1" onClick={() => this.updateProfile()}>Update</button>
                                    </div>
                                    
                                </div>
                            </Col>
                        </Row>
                                        </Tab.Pane>
                                        
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
            <FooterTwo />
        </Fragment>
        )
    }
}

export default MyAccount;