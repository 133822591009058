import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams,Link  } from 'react-router-dom';

import HeaderSeven from '../component/layout/header-7';
import PageHeader from '../component/layout/pageheader';
import FooterTwo from '../component/layout/footer-2';

function Installments(props) {
  const { number } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [insmodalshow, setInsModalShow] = useState(false);
  const [insAmount, setInsAmount] = useState('');
  const [insError, setInsError] = useState(null);
  const [Error, setError] = useState('File should be less than 1MB');
  const [modalshow, setModalShow] = useState(false);
  const [trId, setTrId] = useState(null);
  const [file, setFile] = useState(null);
  const [installment_id, setInstallmentId] = useState(null);
  const [base64Data, setBase64Data] = useState(null);
  const [amounts, setAmounts] = useState([]);


  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      try {
        fileToBase64(selectedFile).then((base64) => {
          setFile(selectedFile);
          setBase64Data(base64);
        });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result.split(',')[1]);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const generateInvoice = (invoice) => {
    window.location.href = `/generate-invoice/${invoice}`;
  };

  const requestSubmit = (event) => {
    event.preventDefault();
    const url = global.config.URL.api.apply + 'upload-verification';

    axios.defaults.headers.common['Authorization'] = `Bearer ${global.config.Token}`;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios
      .post(global.config.URL.api.apply + 'upload-verification', {
        installment_id: installment_id,
        file: base64Data,
      })
      .then((response) => {
        if (response.data.success) {
          setModalShow(false);
          InvoiceInstallments();
          swal({
            title: 'Submitted',
            text: 'Your Request Successfully Submitted',
            icon: 'success',
          });
        }
      });
  };

  const makeInstallment = () => {
    const amount = insAmount;

    if (!amount) {
      setInsError('Please Select Amount');
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${global.config.Token}`;

      axios
        .post(global.config.URL.api.apply + 'make-installment/' + invoice.id, {
          amount: insAmount,
        })
        .then((response) => {
          if (response.data.success) {
            setInsModalShow(false);
            swal(response.data.message);
            InvoiceInstallments();
            swal({
              title: 'Success',
              text: response.data.message,
              icon: 'success',
            });
            window.location.href = '/generate-invoice/' + response.data.installment.invoice_no;
          } else {
            swal(response.data.message);
          }
        });
    }
  };

  const handleInsModal = (status) => {
    if (!status) {
      setInsModalShow(status);
      setInsAmount('');
    } else {
      setInsModalShow(status);
    }

    switch (invoice.installments.length) {
      default:
        return setAmounts([invoice.amount - invoice.total_paid]);
      case 0:
        return setAmounts([1000, Math.round(invoice.amount / 2) - 1000, Math.round(invoice.amount / 2)]);
      case 1:
        return setAmounts([Math.round((invoice.amount - invoice.total_paid) / 2), invoice.amount - Math.round(invoice.total_paid)]);
      case 2:
        return setAmounts([invoice.amount - invoice.total_paid]);
    }
  };

  const handleModal = (status, id) => {
    setModalShow(status);
    setInstallmentId(id);
  };

  const handleInsAmount = (e) => {
    const value = e.target.value;

    if (!isNaN(+value)) {
      setInsAmount(value);
      setInsError('');
    } else {
      setInsError('Please Enter Right Amount');
    }
  };

  const removeInvoice = (i) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${global.config.Token}`;
    swal({
      title: 'Are you sure?',
      text: 'This invoice will never revert',
      icon: 'warning',
      buttons: { cancel: 'NO', text: 'Remove Invoice' },
    }).then((yes) => {
      if (yes) {
        axios.post(global.config.URL.api.apply + 'remove-invoice/' + i).then(function (response) {
          if (response.data.success) {
            swal(response.data.message);
            window.location.href = '/course-registration';
          } else {
            swal(response.data.message);
          }
        });
      }
    });
  };

  const InvoiceInstallments = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${global.config.Token}`;

    const invoiceData = await axios.get(global.config.URL.api.apply + 'invoice-installments/' + number);

    if (invoiceData.data.success) {
      setInvoice(invoiceData.data.invoice);
      setBatch(invoiceData.data.batch);
      setLoading(false);
    }
  };

  const calculateDiscount = (amount, percentage) => {
    const discountAmount = (amount / 100) * percentage;
    return amount - discountAmount;
  };

  const createPDF = async () => {
    const pdf = new jsPDF('landscape', 'px', 'a4');
    const data = document.querySelector('#pdf');
    pdf.html(data).then(() => {
      pdf.save('shipping_label.pdf');
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    InvoiceInstallments();
    
  }, []);

  return (
    <>
      <HeaderSeven />
      <PageHeader title={'My Installments'} curPage={'Installment Page'} />

      {loading ? (
        <InfinitySpin width="200" className="center" color="#4fa94d" />
      ) : (
        <div className="shop-cart pt-3">
          <div className="container">
            <div className="section-wrapper">
              <Row>
                <Col md={12}>
                 
                <Alert className='alert-success'><h3>Invoice</h3></Alert>
                  <div className='cart-top table-responsive'>
                    <table className=''>
                      <thead>
                        <tr>
                          <th>Batch</th>
                          <th>Amount</th>
                          <th>Remaining</th>
                          <th>Courses</th>
                          <th>Status</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice ? (
                          <tr>
                            <td>
                              <span className='badge bg-warning'>
                                {invoice.is_paid ? invoice.batch.number : batch.number}
                              </span>
                            </td>
                            <td>
                              {invoice.offer ? (
                                new Date(invoice.offer.valid_till).getTime() >= new Date().getTime() ? (
                                  <i>
                                    <del>{invoice.amount}</del>{' '}
                                    {calculateDiscount(invoice.amount, invoice.offer.discount_in_percent)}
                                  </i>
                                ) : (
                                  invoice.amount
                                )
                              ) : (
                                invoice.amount
                              )}
                            </td>
                            <td>{invoice.amount - invoice.total_paid}</td>
                            <td>
                              {invoice.details
                                ? invoice.details.map((detail, i) => (
                                    <span className='badge bg-success mr-2' key={i}>
                                      {detail.course.name}
                                    </span>
                                  ))
                                : ''}
                            </td>
                            <td>
                              {invoice.is_active ? (
                                <span className='badge bg-success text-light'>Active</span>
                              ) : (
                                <span
                                  className={
                                    invoice.is_paid
                                      ? 'badge bg-success text-light'
                                      : invoice.partially_paid
                                      ? 'badge bg-info text-light'
                                      : 'badge bg-danger text-light'
                                  }
                                >
                                  {invoice.is_paid ? 'Paid' : invoice.partially_paid ? 'Partially Paid' : 'Unpaid'}
                                </span>
                              )}
                            </td>
                            <td>
                              {invoice.is_paid ? '-' : moment(batch.adm_closing_date).format('Do MMM YY')}
                            </td>
                            <td>
                              <button className='btn btn-success' onClick={() => handleInsModal(true)}>
                                Make Installment
                              </button>
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col md={12}>
                  <Alert className='alert-warning'>
                    <h3>Installments</h3>
                  </Alert>
                  <div className='cart-top table-responsive'>
                    <table className=''>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Invoice Number</th>
                          <th>Due Date</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice.installments.map((installment, i) => (
                          <React.Fragment key={i}>
                            <tr>  {installment.is_paid ? (
                              ''
                            ) : installment.request ? (
                              
                                <td colSpan={5}>
                                <Alert className='alert-danger marquee'>
      <p>Your Request Will Be Verified Within 3-4 Working Days</p>
    </Alert>
                                </td>
                              
                            ) : (
                              ''
                            )}</tr>
                            <tr>
                              <td>
                                <span className={installment.is_paid ? 'badge bg-success' : 'badge bg-danger'}>
                                  {installment.is_paid ? 'Paid' : installment.request ? 'Requested' : 'Unpaid'}
                                </span>
                              </td>
                              <td>{installment.invoice_no}</td>
                              <td>{moment(installment.due_date).format('Do MMM YY')}</td>
                              <td>{installment.amount}</td>
                              <td>
                                {installment.is_paid ? (
                                  ''
                                ) : (
                                  <>
                                  <Link to={'/generate-invoice/'+installment.invoice_no} className='btn btn-success text-light m-2'>Proceed To Payment Mode </Link>
                                  <button className='btn btn-warning m-2' onClick={() => handleModal(true, installment.id)}>
                                    Request For Payment Verification
                                  </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
      <Modal show={insmodalshow} onHide={insmodalshow}>
        <Modal.Header className='bg-us'>
          <Modal.Title className='text-dark'>Make Installment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4} className='text-center'>
              <form className='form-group'>
                {amounts.map((amount, i) => (
                  <div key={i} className='ml-3'>
                    <input
                      type='radio'
                      className='form-check-input ml'
                      name='amount'
                      id={i}
                      value={amount}
                      onChange={(e) => handleInsAmount(e)}
                    />
                    <label htmlFor={i}>Rs: {amount}</label>
                    <br />
                  </div>
                ))}
                <alert className='alert-danger'>{insError}</alert>
              </form>
            </Col>
            <Col md={8}>
              <strong>Select one of the given options on how much amount you want to pay as your fee installment.</strong>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='danger' onClick={() => handleInsModal(false)}>
            Close
          </Button>
          <Button type='submit' variant='success' onClick={() => makeInstallment()}>
            Make
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalshow} onHide={modalshow}>
        <Modal.Header>
          <Modal.Title>Request For Verification</Modal.Title>
        </Modal.Header>
        <form onSubmit={(event) => requestSubmit(event)}>
          <Modal.Body>
            <label>Transection Image</label>
            <input type='file' className='form-control' onChange={(e) => handleFileInputChange(e)} />
            <alert className='alert-danger'>{Error}</alert>
          </Modal.Body>
          <Modal.Footer>
            <Button type='button' variant='danger' onClick={() => handleModal(false)}>
              Close
            </Button>
            <Button type='submit' variant='success'>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <FooterTwo />
    </>
  );
}

export default Installments;
