import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GroupSelect from "../component/sidebar/group-select";
import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
import SkillSelect from "../component/sidebar/skill-select";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import CourseTwo from "../component/section/course-2";
import HeaderSeven from "../component/layout/header-7";




const CoursePage = () => {
    return (
        <Fragment>
            <HeaderSeven />
            <PageHeader title={'All Courses'} curPage={'Course Page'} />
            <CourseTwo/>
            <FooterTwo />
        </Fragment>
    );
}
 
export default CoursePage;