
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import BlogPage from "./page/blog";
import BlogPageTwo from "./page/blog-2";
import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/blog-single";
import CartPage from "./page/cart-page";
import ContactPage from "./page/contact";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
import HomeTwo from "./page/home-2";
import HomeThree from "./page/home-3";
import HomeFour from "./page/home-4";
import HomeFive from "./page/home-5";
import HomeSix from "./page/home-6";
import HomeSeven from "./page/home-7";
import InstructorPage from "./page/instructor";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import ShopPage from "./page/shop";
import ShopDetails from "./page/shop-single";
import SignupPage from "./page/signup";
import TeamPage from "./page/team";
import TeamSingle from "./page/team-single";
import ProfileSignup from "./page/profile-signup";
import CourseRegistration from "./page/course-registration";
import Protected  from "./component/common/auth/Protected";
import Invoice  from "./page/Invoice";
import MyAccount from "./page/MyAccount";
import GenerateInvoice from "./page/GenerateInvoice";
import Installments from "./page/Installments";



function App() {
	const auth = JSON.parse(localStorage.getItem("authUser"))
    const isAutheticated = auth ? auth.login : false;
   
    const isProfile = auth ? auth.profile : false;
  

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<HomeSeven />} />
				<Route path="index-2" element={<HomeTwo />} />
				<Route path="index-3" element={<HomeThree />} />
				<Route path="index-4" element={<HomeFour />} />
				<Route path="index-5" element={<HomeFive />} />
				<Route path="index-6" element={<HomeSix />} />
				<Route path="index-7" element={<HomeSeven />} />
				<Route path="course" element={<CoursePage />} />
				<Route path="/single-course-detail/:slug" element={<CourseSingle />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-2" element={<BlogPageTwo />} />
				<Route path="blog-3" element={<BlogPageThree />} />
				<Route path="blog-single" element={<BlogSingle />} />
				
				<Route path="team" element={<TeamPage />} />
				<Route path="team-single" element={<TeamSingle />} />
				<Route path="instructor" element={<InstructorPage />} />
				<Route path="D" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopDetails />} />
				
				<Route path="search-page" element={<SearchPage />} />
				<Route path="search-none" element={<SearchNone />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="login" element={<LoginPage />} />
				<Route path="signup" element={<SignupPage />} />

				<Route path="about" element={<AboutPage />} />
				<Route path="ceo-message" element={<AboutPage />} />
				<Route path="vision" element={<AboutPage />} />
				<Route path="why-niais" element={<AboutPage />} />



				<Route path="profile-signup" element={
					<Protected isSignedIn={isAutheticated} profile={true}>
					<ProfileSignup />
				  </Protected>
				} />
				<Route
					path="/course-registration"
					element={
					<Protected isSignedIn={isAutheticated} profile={isProfile}>
						<CourseRegistration />
					</Protected>
					}
				/>
				<Route
					path="/my-cart"
					element={
					<Protected isSignedIn={isAutheticated} profile={isProfile}>
						<CartPage />
					</Protected>
					}
				/>
						<Route
					path="/my-invoices"
					element={
					<Protected isSignedIn={isAutheticated} profile={isProfile}>
						<Invoice />
					</Protected>
					}
				/>
					<Route
					path="/my-account"
					element={
					<Protected isSignedIn={isAutheticated} profile={isProfile}>	
						<MyAccount />
					</Protected>

					
					}
				/>
					<Route
					path="/generate-invoice/:number"
					element={
					<Protected isSignedIn={isAutheticated} profile={isProfile}>	
						<GenerateInvoice />
					</Protected>

					
					}
				/>
						<Route
					path="/installments/:number"
					element={
					<Protected isSignedIn={isAutheticated} profile={isProfile}>	
						<Installments />
					</Protected>

					
					}
				/>
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
