
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "National Initiative for Artificial Intelligence & Security (NIAIS)";
const title = "Welcome to NIAIS";



const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb ">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    <img src="assets/images/feedback/01.jpg" alt="student feedback" />
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <h4>NATIONAL INITIATIVE FOR AI & SECURITY (NIAIS)</h4>
                            <p className="text-justify text-dark lh-30">
                            The NIAIS institute, which was founded in 2019, provides a variety of courses in cutting-edge technology. Emerging technologies are reshaping our future at an unprecedented rate in the quickly changing world of today. Being innovative is not only advantageous, but also necessary. We at NIAIS are dedicated to providing you with the education and training necessary to fully use the potential of these ground-breaking technologies. Discover and innovate with us as we explore the most fascinating new technologies of our day.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;