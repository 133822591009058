import { Component, Fragment } from "react";
import FooterTwo from "../component/layout/footer-2";
import HeaderThree from "../component/layout/header-3";
import AboutTwo from "../component/section/about-2";
import AchievementTwo from "../component/section/achievement-2";
import Achievement from "../component/section/achievement";
import Blog from "../component/section/blog";
import CategoryTwo from "../component/section/category-2";
import CourseTwo from "../component/section/course-2";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";
import BannerThree from "../component/section/banner-3";
import Register from "../component/section/register";
import FeatureFour from "../component/section/feature-4";
import InstructorThree from "../component/section/instructor-3";
import TeamPage from "./team";
import CourseThree from "../component/section/course-3";
// import Student from "../component/section/student";

const HomeThree = () => {
    return (
        <Fragment>
            <HeaderThree />
            <BannerThree />
            <TeamPage/>
            <Student/>
            {/* <AboutTwo /> */}
            <CourseThree />
            {/* <Register /> */}
            <FeatureFour />
            <Achievement/>
           <InstructorThree/>
            {/* <Footer /> */}
            <FooterTwo />
        </Fragment>
    );
}
 
export default HomeThree;