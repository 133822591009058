
import { Link } from "react-router-dom";


const title = "NIAIS (National Initiative for AI & Security)";
const desc = "Education & Training Organization";
const btnText = "Register Now";



const BannerThree = () => {
    return (
        <section className="banner-section style-3">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row text-center">
                        <div className="col-xl-12">
                            <div className="banner-content "> 
                                <h2 className="title text-light">{title}</h2>
                                <p className="desc text-light">{desc}</p>
                                {/* <Link to="/signup" className="lab-btn"><span>{btnText}</span></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default BannerThree;