import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import InstructorThree from "../component/section/instructor-3";
import Achievement from "../component/section/achievement";




const subTitle = "We Are NIAIS ";
const title = "National Initiative for AI & Security.";
const desc = "The mission of NIAIS is to reshape and contribute to Pakistan's economy by adopting the new ERA of the 4th Industrial Revolution and Futuristic technologies. The Initiative on 4th Industrial Revolution was initiated by NIAIS and under the supervision of Governor Punjab Chaudhary Muhammad Sarwar Proceeding through the Past, Present, and Future of Movement, from Ancient Exploration to Future of Technologies, by this Initiative at the National level, Pakistan can become a Global I.T hub and export its product and services to other countries in future technologies.";

const year = "30+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Be a competent Ambassador',
        desc: 'The Ambassador program is a program to create the Opportunities for Pakistan to make the global working',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'International Internship.',
        desc: 'NIAIS has collaborations with different universities & we tend to change the future of our Young Generation by offering them internship opportunities',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Summer/Internships/Careers',
        desc: 'NIAIS Technologies offers the Summer Internship program for indulging students in the latest techniques and train them for the future',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <HeaderThree />
            <PageHeader title={'About NIAIS'} curPage={'About'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/niais.png" alt="about" />
                                </div>
                              
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="about-right">
                                <div className="section-header">
                                    {/* <span className="subtitle">{subTitle}</span> */}
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InstructorThree />
           
            <Achievement />
            
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 