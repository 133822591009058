
import { Link } from "react-router-dom";

const desc = "A step for the national cause of educational and technology revolution. The agenda of the National conference was implementing the 5.0 industry in Pakistan by providing practical and productive hands-on training to make a technical workforce for the upcoming future job market and entrepreneurship opportunities.";
const courseTitle = "Useful Info";
const quickTitle = "Quick Links";
;

const addressList = [
    {
        iconName: 'icofont-google-map',
        text: 'Arfa Tower Lahore,Punjab',
    },
    {
        iconName: 'icofont-phone',
        text: '+92-308-2444618',
    },
    {
        iconName: 'icofont-envelope',
        text: 'support@niais.org',
    },
]

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/niais.org',
        className: 'facebook',
    },
    {
        iconName: 'icofont-youtube',
        siteLink: 'https://www.youtube.com/channel/UCLgy1-QRXkFsZedZMjPBREA',
        className: 'pinterest',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/niais/',
        className: 'linkedin',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/niais_official/',
        className: 'instagram',
    },
   
]

const courseList = [
    {
        text: 'Verification',
        link: '#',
    },
    {
        text: 'Event Gallery',
        link: '#',
    },
    {
        text: 'Scholarship',
        link: '#',
    },
    {
        text: 'Course Outline',
        link: '#',
    },
    {
        text: 'Contact us',
        link: '#',
    }
]

const quickList = [
    {
        text: 'Home',
        link: '/',
    },
    {
        text: 'Courses',
        link: '/course',
    },
    {
        text: 'Gallery',
        link: '#',
    },
    {
        text: 'Register Now',
        link: 'login',
    },
    {
        text: 'Privacy Policy',
        link: '#',
    },
    {
        text: 'Terms of Use',
        link: '#',
    },
]




const FooterTwo = () => {
    return (
        <footer className="style-2">
            <div className="footer-top primary-view padding-tb">
                <div className="container">
                    <div className="row  ">
                        <div className="col-md-6">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <img src="/assets/images/logo/01.png" alt="education" />
                                        </div>
                                        <div className="content">
                                            <p className="text-justify">{desc}</p>
                                            <ul className="lab-ul office-address">
                                                {addressList.map((val, i) => (
                                                    <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                                ))}
                                            </ul>
                                            <ul className="lab-ul social-icons">
                                                {socialList.map((val, i) => (
                                                    <li key={i}>
                                                        <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{courseTitle}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                                {courseList.map((val, i) => (
                                                    <li key={i}><a href={val.link}>{val.text}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{quickTitle}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                                {quickList.map((val, i) => (
                                                    <li key={i}><a href={val.link}>{val.text}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <p>&copy; 2023 <Link to="/">NIAIS</Link> Designed by <a href="https://niais.org"  rel="noreferrer" target="_blank">NIAIS</a> </p>
                        
                    </div>
                </div>
            </div>
            <div class="icon-contact" id="icon-contact">
     
        <div class="icon-div text">
            <a href="https://wa.me/+923082444618" target="blank">
                <p class="ico-p text-light" ><span><i class="icofont-whatsapp" aria-hidden="true"></i></span>

                </p>
            </a>
        </div>
    </div>
        </footer>
    );
}
 
export default FooterTwo;