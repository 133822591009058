import React from 'react'
import { Navigate } from 'react-router-dom'
function Protected({ isSignedIn,profile ,children }) {
  if (!isSignedIn) {
    return <Navigate to="/login" replace />
  }else if(isSignedIn && !profile)
  {
    return <Navigate to="/profile-signup" replace />
  }
  return children
}
export default Protected