import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Rating from "../component/sidebar/rating";


const achieveList = [
    {
        imgUrl: 'assets/images/achive/01.png',
        imgAlt: 'achive thumb rajibraj91 rajibraj',
        title: 'NIAIS ONLINE',
        desc: 'Seamlessly engage technically sound coaborative reintermed goal oriented content rather than ethica',
        btnText: '+92-313-4444059',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/achive/02.png',
        imgAlt: 'achive thumb rajibraj91 rajibraj',
        title: 'NIAIS ONSITE',
        desc: 'Seamlessly engage technically sound coaborative reintermed goal oriented content rather than ethica',
        btnText: ' +92-306-7555088',
        siteLink: '#',
    },
]



const TeamPage = () => {
    return (

                        <div className="achieve-part mt-5 mb-5">
                            <div className="row g-4 row-cols-1 row-cols-lg-2">
                                {achieveList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="achieve-item">
                                            <div className="achieve-inner">
                                                <div className="achieve-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="achieve-content">
                                                    <h4>{val.title}</h4>
                                                    <p>{val.desc}</p>
                                                    <a href={val.siteLink} className="lab-btn"><span>{val.btnText}</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                   
       
    );
}
 
export default TeamPage;