
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import "../helper/helper";
import axios from 'axios';

const subTitle = "Featured Courses";
const title = "Pick A Course To Get Started";

function CourseTwo() {
    useEffect(() => {
        const getData = async () => {
            const domains = await axios.get(global.config.URL.api.public + 'domain-courses');
            setDomains(domains.data.domains[0]);
            console.log(domains.data.domains[0],'domains.data')
        }
        getData();
    }, []);

    const [domains, setDomains] = useState([])




    return (
        <div className="course-section padding-tb ">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                            {domains.courses ? domains.courses.map((val, i) => (
                                 <Link to={'/single-course-detail/' + val.slug}>
                                <div className="col" key={i}>
                                    <div className="course-item text-center">
                                        <div className="course-inner">
                                            <div className="course-thumb">
                                                <img src={global.config.URL.img.public + val.image} alt={`Card Img`} />
                                            </div>
                                            <div className="course-content">
                                              
                                              
                                                 
                                                
                                               <p className='text-center'> <Link to={'/single-course-detail/' + val.slug}><h4 className='text-center'>{val.name}</h4></Link></p>
                                                <div className="text-center">
                                                
                                                  
                                                 <p className='text-center'> <strong className='text-bold'>Course Fee:</strong>
                                                        <span className="ratting-count"><del className='mr-3'>{val.discount_fee }</del><strong className='text-success'>{val.fee}</strong></span>
                                                        </p><p className='text-center'><strong className='text-bold'>Course Duration:</strong>
                                                        <span className="ratting-count">{val.course_detail.duration}</span>
                                                        </p>
                                                    </div>
                                              
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                
                                </Link>
                            )): ""}
                        </div>
                </div>
            </div>
        </div>
    );
}
 
export default CourseTwo;