import { Component, Fragment } from "react";
import FooterTwo from "../component/layout/footer-2";
import HeaderSeven from "../component/layout/header-7";
import BannerTwo from "../component/section/banner-2";
import BlogThree from "../component/section/blog-3";
import CourseFour from "../component/section/course-4";
import Feature from "../component/section/feature";
import InstructorThree from "../component/section/instructor-3";
import NewsletterTwo from "../component/section/newsletter-2";
import Offer from "../component/section/offer";
import CourseThree from "../component/section/course-3";
import CourseTwo from "../component/section/course-2";
import BannerThree from "../component/section/banner-3";
import AboutTwo from "../component/section/about-2";




const HomeSeven = () => {
    return (
        <Fragment>
            <HeaderSeven />
            <BannerThree />
            <AboutTwo/>
           
            <CourseTwo />
            {/* <FeatureFour /> */}
           
            <FooterTwo />
        </Fragment>
    );
}
 
export default HomeSeven;