

const subTitle = "Choose Any One Course";
const title = "Learn Everything with NIAIS";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'State of the Art training.',
        desc: 'NIAIS is one of the best top-notch platforms offering State of the Art trainings on Hi-Tech Courses & edge cutting 5.0 technologies.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Globally Recognized trainers.',
        desc: "Our trainers are graduated from Top Rank Universities across the globe and most of them are Ph.D.'s and M.Phil degree holders Trainers tend to update you on the latest courses and Day-Modern technologies.",
    },
    {
        iconName: 'icofont-graduate',
        title: 'Summer/Internships/Careers.',
        desc: 'NIAIS Technologies offers the Summer Internship program for indulging students in the latest techniques and train them for the future',
    },
]

const featureRightList = [
    {
        iconName: 'icofont-paper-plane',
        title: 'Be a competent Ambassador.',
        desc: 'The Ambassador program is a program to create the Opportunities for Pakistan to make the global working.',
    },
    {
        iconName: 'icofont-site-map',
        title: 'International Internship.',
        desc: 'NIAIS has collaborations with different universities & we tend to change the future of our Young Generation by offering them internship opportunities',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Top Notch courses.',
        desc: "NIAIS is making the future in an era of artificial intelligence, Now it's your chance to get yourself trained in 5.0 edge-cutting technologies.",
    },
]

const FeatureFour = () => {
    return (
        <section className="feature-section style-2 bg-feature padding-tb pb-0">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle yellow-color">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center align-items-center">
                        <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                            <div className="left text-lg-end">
                                {featureLeftList.map((val, i) => (
                                    <div className="feature-item" key={i}>
                                        <div className="feature-inner flex-lg-row-reverse">
                                            <div className="feature-icon">
                                                <i className={val.iconName}></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                            <div className="right">
                                {featureRightList.map((val, i) => (
                                    <div className="feature-item" key={i}>
                                        <div className="feature-inner">
                                            <div className="feature-icon">
                                                <i className={val.iconName}></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                            <div className="feature-thumb">
                                <div className="abs-thumb">
                                    <img src="assets/images/feature/robot.png" alt="education" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default FeatureFour;