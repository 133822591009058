import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table } from 'react-bootstrap';
import PageHeader from "../component/layout/pageheader";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";

import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { InfinitySpin } from 'react-loader-spinner'
import HeaderSeven from "../component/layout/header-7";
class Invoice extends Component {

    constructor(props){
        super(props);
        this.state = {
            invoices: [],
            batch: null,
            loading:true,
            prop: this.props
        }

        this.myInvoices = this.myInvoices.bind(this);
        this.calculateDiscount = this.calculateDiscount.bind(this);
        this.removeInvoice = this.removeInvoice.bind(this);
    }
    componentDidMount()
    {
      
      this.myInvoices();
    }
  
     removeInvoice(i) {
      axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${global.config.Token}`;
        swal({
          title: "Are you sure?",
          text: `This invoice will never revert`,
          icon: "warning",
          buttons: {cancel:"NO",text:"Remove Invoice"},
        })
        .then((yes) => {
          if (yes) {
             axios.post(global.config.URL.api.apply + 'remove-invoice/'+i)
            .then(function (response) {
              // handle success
            if(response.data.success)
            {
              swal(response.data.message);
              window.location.href ="/course-registration";
            }else{
              swal(response.data.message);
            }
            });
          } 
        });
     
  }
      async myInvoices  () {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const invoice = await axios.get(global.config.URL.api.apply + 'my-invoices');
        if(invoice.data.success)
        {
            this.setState({invoices: invoice.data.invoices,loading:false,batch:invoice.data.batch},function(){
            });  
        }
    }


    calculateDiscount(amount,percentage)
    {
     var discountAmount = (amount/100)*percentage;
        return amount - discountAmount;
    }

     
    
    
    render() {
       
        return (
            <Fragment>
            <HeaderSeven />
            <PageHeader title={'My Invoices'} curPage={'Invoice Page'} />
          
            { this.state.loading ? <InfinitySpin  width='200' className="center" color="#4fa94d" /> : 
            
              
            <div className="shop-cart pt-3">
                <div className="container">
                    <div className="section-wrapper">
                    <Row>
                      {   this.state.invoices.map((invoice,i)=>(
                    <Col md={12}>
                        <div className="cart-top table-responsive">
                            <table className="">
                                <thead>
                                <tr>
                                <th>Batch</th>

<th>Amount</th>
<th>Remaining</th>
<th>Courses</th>
<th>Status</th>

<th>Created Date</th>
<th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
      <td><span className='badge bg-warning'>{invoice.is_paid ? invoice.batch.number : this.state.batch.number}</span>
      
      </td>
      
      <td> {invoice.offer ? new Date(invoice.offer.valid_till).getTime() >= new Date().getTime()   ? <i><del>{invoice.amount}</del> {this.calculateDiscount(invoice.amount,invoice.offer.discount_in_percent)}  </i> : invoice.amount : invoice.amount} </td>
      <td>
{
      invoice.amount - invoice.total_paid
}

      </td>
      <td>{invoice.details ? 
      invoice.details.map((detail,i)=>( <spna className="badge bg-success mr-2" key={i}>{detail.course.name}</spna> )) :'' }</td>
      <td>
      
        {
          invoice.is_active ? <span className="badge bg-success text-light">Active</span>
         : <span className={invoice.is_paid ? 'badge bg-success text-light' :  invoice.partially_paid ? 'badge bg-info text-light' : 'badge bg-danger text-light'  }>{invoice.is_paid ? "Paid" : invoice.partially_paid ? "Partially Paid" : "Unpaid"} { invoice.is_paid ? "" : <i className="fa fa-trash ml-2 text-light" onClick={() => this.removeInvoice(invoice.invoice_no)} title='Remove Invoice'></i> }</span>
       
        }
        </td>
      <td>{invoice.is_paid ? "-" : moment(this.state.batch.adm_closing_date).format("Do MMM  YY")}</td>
      
      <td className="text-center">
        {
          invoice.is_active ? <a href='https://onsitelms.pftpedu.org/' target={'_blank'} rel="noopener noreferrer">Login to LMS</a>
       : <a href={'./installments/'+invoice.invoice_no} rel="noopener noreferrer" className='btn btn-sm btn-warning'>Fee Details</a>
        }
        </td>
   
    </tr>
                                  
                                </tbody>
                            </table>
                        </div>
                        
                                
                        </Col>
                        ))
                      }
                        </Row>
                    </div>
                </div>
            </div>
            
    }
              
                           
                                
                         <FooterTwo />
                         </Fragment>
        
        )
    }
}

export default Invoice