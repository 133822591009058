
import { Link,useNavigate  } from "react-router-dom";


const title = "You can pay your fee through mention modes ";
const btnText = "Contact Us";

const skillList = [
    // {
    //     imgUrl: '/assets/images/skill/icon/01.jpg',
    //     imgAlt: 'skill rajibraj91 rajibraj',
    //     title: 'Pay Via Jazz Cash',
    //     desc: 'Hafiz Muhammad Waqas',
    //     no: '0309 0666609',
    // },
    // {
    //     imgUrl: '/assets/images/skill/icon/02.jpg',
    //     imgAlt: 'skill rajibraj91 rajibraj',
    //     title: 'Pay Via Easy Paisa',
    //     desc: 'Hafiz Muhammad Waqas',
    //     no: '0323 6200760',

    // },
    {
        imgUrl: '/assets/images/skill/icon/03.jpg',
        imgAlt: 'skill rajibraj91 rajibraj',
        title: 'Bank Alflah',
        desc: 'EMERGING TECHNOLOGIES',
        no: '56195001690152',

    },
    {
        imgUrl: '/assets/images/skill/icon/04.jpg',
        imgAlt: 'skill rajibraj91 rajibraj',
        title: 'Pay At Office',
        desc: 'Arfa Technology Park',
        no: 'Level# 8, Office# 18, Lahore',

    },
    
]


const Skill = (props) => {
    const goBack = () => {
        window.history.back();
      };
    
    return (
        <div className="skill-section pt-5 pb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                        <div className="section-header">
                            <h2 className="title">{title}</h2>
                            <Link to="/contact" className="btn btn-success text-light m-3"><span>{btnText}</span></Link>
                            <button onClick={() => goBack()} className="btn btn-warning m-3 text-"><span>{"Already Paid? Request for Verify"}</span></button>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="section-wrpper">
                            <div className="row g-4 justify-content-center row-cols-sm-2 row-cols-1">
                                {skillList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="skill-item">
                                            <div className="skill-inner">
                                                <div className="skill-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="skill-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                    <p>{val.no}</p>
                                                    <p>{i=== 3 ? " Cash " + props.amount : "Amount " + props.amount}/-</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Skill;