
import React, { Fragment,useEffect,useState } from 'react';

import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import HeaderSeven from "../component/layout/header-7";
import FooterTwo from "../component/layout/footer-2";
import axios from 'axios';
import swal from 'sweetalert'
import { Helmet } from 'react-helmet';


const title = "Create Account";
const socialTitle = "Register With Social Media";
const btnText = "Apply Now";


let socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]


const SignupPage = () => {
    const [loading,setLoading] = useState(false);
    const [show,setShow] = useState(false);
    useEffect(() => {
       
        const form = document.getElementById("form_registration");
        const name = document.getElementById("registration_name");
        const mob = document.getElementById("registration_mob");
        const email = document.getElementById("registration_email");
        
        const password = document.getElementById("registration_password");
        const cpassword = document.getElementById("registration_cpassword");

        // Validation
        var isName=false;
        var isEm=false;
        var isMob=false;
        var isPass=false;

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const nameValue = name.value.trim();
            const mobValue = mob.value.trim();
            const emailValue = email.value.trim();
            const passwordValue = password.value.trim();
            const cpasswordValue = cpassword.value.trim();
           
            if (nameValue === "") {
                setError(name, "Name can't be blank");
            } else if(nameValue.length > 150)
            {
                setError(name, "Enter Valid Name");
            } else {
                isName = true;
                setSuccess(name);
            }
            if (mobValue === "") {
                setError(mob, "Mobile Number can't be blank");
            }else if (!isMobile(mobValue) || mobValue.length !==11) {
                console.log(mobValue.length ,'mobValue.length ')
                setError(mob, "Mobile Number must be 11 digits");
            } else {
                isMob = true;
                setSuccess(mob);
            }
            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue) || emailValue.length > 150) {
                setError(email, "Not a valid email");
            } else {
                isEm = true;
                setSuccess(email);
            }
            if (passwordValue === "") {
                setError(password, "Password can't be blank");
            } else if (passwordValue.length < 8) {
                setError(password, "Password must be at least 8 characters in length");
            }else if (passwordValue.length > 150) {
                setError(password, "Password must be less than least 150 or greater than 8 characters in length");
            } 
            else {
                isPass = true;
                setSuccess(password);
            }

            if (cpasswordValue === "" || passwordValue !== cpasswordValue) {
                setError(cpassword, "Password doesn't match");
            } else {
                setSuccess(cpassword);
            }
           
            if(isName && isEm && isMob && isPass)
            {
               register();
            }
           
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".registration_input-msg");
            formControl.className = "form-group text-start error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".registration_input-msg");
            formControl.className = "form-group";
            errorMsg.innerText = "";
           
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

        function isMobile(mobile) {
            return mobile.match('[0-9]{11}');
        }

        const register = async () =>{
            setLoading(true);
            const res = await axios.post(global.config.URL.api.user + 'register',{
               name: name.value.trim(),
               email: email.value.trim(),
               mob: mob.value.trim(),
               password: password.value.trim(),
             });
             if(res && res.data.success)
             {  
               
               localStorage.setItem("authUser", JSON.stringify(res.data));
               swal({
                title: "Registered!",
                text: "Your account has been successfully registered!",
                icon: "success",
                button: "OK!",
              }).then(() => {
                    window.location.href = 'profile-signup';
                });
              
             }else{
              setLoading(false)
              const message = res.data.message;
              if(message.name)
              {
               setError(name,  message.name);
              }
              if(message.email)
              {
               setError(email,  message.email);
              }
              if(message.mob)
              {
               setError(mob,  message.mob);
              }
              if(message.password)
              {
               setError(password,  message.password);
              }
              
        }
       }   
    },[]);

    function howToApply()
    {
        swal({
            title: "Apply Procedure",
            text: ` Step-1 Fill Registration Form
            Step-2 Create Profile
            Step-3 Select Course
            Step-4 Fee Procedure
            Step-5 Generate Invoice
            Step-6 Pay fee
            Step-7 Verification Procedure
            Step-8 Upload invoice 
            Step-9 Submit`,
            html: true
          });
    }
    return (
        <Fragment>
            <HeaderSeven />
           
           
            
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}<a href="#!" onClick={() => howToApply()}><sup className='apply'>Apply Procedure?</sup></a></h3>
                        <form className="account-form" id='form_registration'>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="User Name"
                                    id="registration_name"
                                />
                                 <span className="registration_input-msg text-danger"></span>
                            </div>
                            <div className="form-group">
                                <input
                                 id="registration_mob" 
                                    type="text"
                                    name="mob"
                                    placeholder="User Mobile"
                                />
                                 <span className="registration_input-msg text-danger"></span>
                            </div>
                            <div className="form-group">
                                <input
                                   id="registration_email"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                />
                                 <span className="registration_input-msg text-danger"></span>
                            </div>
                            <div className="form-group">
                                <input
                                type={show ? 'text' : 'password'} placeholder="Password" id="registration_password" 
                                    name="password"  
                                />
                                 <span className="registration_input-msg text-danger"></span>
                            </div>
                            <div className="form-group">
                            <input type={show ? 'text' : 'password'} placeholder="Confirm password" id="registration_cpassword" />
                                            <span className="registration_input-msg text-danger"></span>
                                           {show === true ? <i class="icofont-eye float-right" aria-hidden="true" onClick={() => setShow(false)}></i> : <i class="icofont-eye-blocked text-success float-right" onClick={() => setShow(true)} aria-hidden="true"></i> } 
                                           
                            </div>
                            <div className="form-group">
                                <button className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                           
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </Fragment>
    );
}
 
export default SignupPage;