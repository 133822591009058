import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import { InfinitySpin } from 'react-loader-spinner'
import axios from 'axios';
import "../component/helper/helper";
import swal from 'sweetalert';
import { Row,Col } from "react-bootstrap";
import HeaderSeven from "../component/layout/header-7";

class CartPage extends Component {

   
    constructor(props){
        super(props);
        this.state = {
            cart: [],
            subAmount : 0,
            amount : 0,
            installment : 0,
            discountinPercent: 0,
            discount : 0,
            loading :true,
            promoLoading :false,
            promo:'',
            promo_id:null,
            pay:null,
        }

        this.myCart = this.myCart.bind(this);
        this.getAmount = this.getAmount.bind(this);
        this.checkout = this.checkout.bind(this);
        this.promo = this.promo.bind(this);
        this.b1g1 = this.b1g1.bind(this);
        this.b1a1 = this.b1a1.bind(this);
        this.confiramCheckout = this.confiramCheckout.bind(this);
        
    }
    componentDidMount() {
       
        this.myCart();
    }
    async promo  (e) {
        this.setState({promoLoading:true})
        e.preventDefault();
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const promo = await axios.get(global.config.URL.api.apply + 'promo/' + this.state.promo);
        if(promo.data.success)
        {
       console.log(promo)
          this.setState({promoLoading:false})
          
            window.setTimeout(()=> window.scrollTo (0,250), 500) 
           
            this.setState({discountinPercent:promo.data.offer.discount_in_percent,promo_id:promo.data.offer.id},function(){
               if(promo.data.offer.promo === "B1G1")
               {   
               
                     this.b1g1();
                    
               }else if(promo.data.offer.promo === "B1A1")
               {
                this.b1a1();
               }
               else{
                this.getAmount();
                swal("You Got "+promo.data.offer.discount_in_percent+"% Discount", "", "success");
               }
               
                
            });  
        }else{
            this.setState({promoLoading:false})
              swal("Error",promo.data.message, "error");
        }
    }

    async myCart  () {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const cart = await axios.get(global.config.URL.api.apply + 'my-cart');
        if(cart.data.success)
        {
            this.setState({cart: cart.data.cart,loading:false},function(){
                this.getAmount();
            });  

            console.log(cart,'cart')
        }
    }
    async removeFromCart(id,i) {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const remove = await axios.get(global.config.URL.api.apply + 'remove-from-cart/'+id);
        let cart = [...this.state.cart]
        cart.splice(i, 1)
        this.setState({ 
            cart: cart,
        },function(){
            this.getAmount();
            
        })
        swal(remove.data.message);
    }
    b1a1(){
        var a1 = false;
        var b1 = false;
        if(this.state.cart.length  > 1  )
        {
            for(var i=0; i < this.state.cart.length; i++ )
            {
                 if(this.state.cart[i].course.domain_id === 7 )
                 {
                    a1 = true;
                 }else{
                    b1 = true;
                 }
            }
           
           if(a1 === true && b1 === false)
           {
            this.setState({discountinPercent:0,promo_id:null})
            swal("Please Select Basic Courses to Avail this offer");
           }
          else if(a1 === false && b1 === true)
           {
            this.setState({discountinPercent:0,promo_id:null})
            swal("Please Select Atlest 1 Advance Course to Avail this offer");
           }
           else{
            swal("You Got "+this.state.discountinPercent+"% Discount", "", "success");
             this.getAmount();
           }
           }
           else{
            this.setState({discountinPercent:0,promo_id:null})
            swal("Please Select Atleast 2 Courses", "", "error");
           }
    }

    b1g1()
    {  
        var a1 = false
        if(this.state.cart.length  > 1  )
        {
            for(var i=0; i < this.state.cart.length; i++ )
            {
                 if(this.state.cart[i].course.domain_id === 7)
                 {
                  a1 = true;
                 }

            }
           if(a1)
           {
            this.setState({discountinPercent:0,promo_id:null})
            swal("Please Select Basic Courses to Avail this offer");
           }else{
            swal("You Got "+this.state.discountinPercent+"% Discount", "", "success");
             this.getAmount();
           }
           }
           else{
            this.setState({discountinPercent:0,promo_id:null})
            swal("Please Select Atleast 2 Basic Courses", "", "error");
           }
    }

    getAmount()
    {
        var subAmount = 0;
       for(var i=0; i < this.state.cart.length; i++)
       {
          subAmount = subAmount +  this.state.cart[i].course.fee;
       }
       var discountAmount = (subAmount/100)*this.state.discountinPercent;
       var amount  = subAmount - discountAmount
       this.setState({ 
        subAmount: subAmount,
        discount:discountAmount,
        amount: amount,
        installment: amount + (Math.round(amount * 0.16))
    })
    }
    confiramCheckout()
    {
        if(!this.state.pay)
        {
            swal({
                text: "Please Select Payment Mode",
                icon: "error",

              }) 
        }else{
            swal({
                title: "Are you sure?",
                text: `Your invoice amount is Rs:${this.state.pay === 1 ? this.state.amount : this.state.installment}`,
                icon: "warning",
                buttons: {cancel:"NO",text:"GENERATE INVOICE"},
              })
              .then((yes) => {
                if (yes) {
                 this.checkout()
                } else {
                  
                }
              });
        }
     
    }
    checkout = async() =>{

        if(!global.config.Token){
            window.location.href = '/login';
        }
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;        
        const res = await axios.post(global.config.URL.api.apply + 'checkout',{
            offer : this.state.promo_id,
            pay : this.state.pay,
          },);
          if(res && res.data.success)
          {
            if( res.data.invoice.mode === 1)
            {
                window.location.href = 'generate-invoice/' +res.data.invoice_no;

            }else{
                window.location.href = 'installments/' +res.data.invoice.invoice_no;
            }
            
           }else{
            swal("Warning",res.data.message, "warning");

            swal({
                title: "Warning",
                text: res.data.message,
                icon: "warning",
                buttons: {cancel:"NO",text:"OK"},
              })
              .then((yes) => {
                if (yes) {
                    window.location.href = '/my-invoices'
                } else {
                    window.location.href = '/'
                }
              });
          
           }
        

    }
    render() {
    return (
        <Fragment>
            <HeaderSeven />
            <PageHeader title={'My Cart'} curPage={'Cart Page'} />
            { this.state.loading ? <InfinitySpin  width='200' className="center" color="#4fa94d" /> : 
            
              
            <div className="shop-cart pt-3">
                <div className="container">
                    <div className="section-wrapper">
                    <Row>
                    <Col md={8}>
                        <div className="cart-top">
                            <table>
                                <thead>
                                    <tr>
                                        
                                        <th className="cat-price"></th>
                                        <th className="product-name">Course</th>
                                                    <th className="product-price">Fee</th>
                                                    <th className="cat-product"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                                 
                                                 this.state.cart.map((cart, i) => (
                                                  <tr  key={i}>
                                                 
                                                  <td className="product-thumbnail"><a href='#!' onClick={() => {window.location.href=`${process.env.PUBLIC_URL + cart.course.slug}`}}><img src={ ""} alt="" /></a></td>
                                                  <td className="product-title"><a href='#!' onClick={() => {window.location.href=`${process.env.PUBLIC_URL + cart.course.slug}`}}>{cart.course.name}</a></td>
                                                  <td className="product-price text-center"><span className="amount">Rs:{cart.course.fee}</span></td>
                                                  <td className="product-remove"><a href="#!" onClick={() => this.removeFromCart(cart.id,i)}><i className="icofont-trash text-danger"></i></a></td>
                                                 </tr>
                                                 ))
                                                 
                                              }       
                                </tbody>
                            </table>
                        </div>
                        <Row>
                    <Col md={6}>
                    <form action="#!" className="input-group" onSubmit={(e) => this.promo(e)}>
                                                    <input type="text" className="form-control" onChange={e => this.setState({promo:e.target.value})} value={this.state.promo} placeholder="Promo code (optional)" required />
                                                   {this.state.promoLoading ? <button disabled className="btn btn-success"> <InfinitySpin  width='100' color="#ffb200" /></button> : <button type="submit" className="btn btn-success text-light">Check Promo</button>} 
                                                </form>
                        </Col>
                        <Col md={6}>
                        <a className="btn btn-warning text-light btn-block" href={process.env.PUBLIC_URL + "/course-registration"}>Update cart</a>
                        </Col>
                        
                        </Row>
                                
                        </Col>
                       <Col lg="4" md="6">
                          <div className="cart-top">
                            <table>
                                <thead>
                                    <tr>
                                        
                                        <th className="cat-price text-center" colSpan={2}>Cart Summary</th>
                                       
                                    </tr>
                                  
                                </thead>
                                <tbody>
                                <tr>
                                        
                                        <td>Sub Total</td>
                                        <td>{this.state.subAmount}</td>
                                       
                                    </tr>
                                    <tr>
                                        
                                        <td>Discount</td>
                                        <td>({this.state.discountinPercent}%) <span>{this.state.discount}</span></td>
                                       
                                    </tr>
                                   
                                    <tr>
                                        <td><input  type="radio" onClick={() => this.setState({pay:1})} name='pay' className='check-box mr-1'/> Full Fee </td>
                                        <td>{this.state.amount}</td>
                                    </tr>
                                    <tr>
                                        
                                        <td> In Installment <input  type="radio" onClick={() => this.setState({pay:2})} name='pay' className='check-box mr-1'/></td>
                                        <td>{this.state.installment}</td>
                                       
                                    </tr>
                                              <tr>
                                    <td colSpan={2}><button type="button" onClick={() => this.confiramCheckout()} className="btn btn-success btn-block">Proceed to checkout</button></td>
                                   </tr>
                                </tbody>
                                </table>
                                </div>

                                </Col>
                        </Row>
                    </div>
                </div>
            </div>
            
    }
            <FooterTwo />
        </Fragment>
        )
    }
}

export default CartPage;