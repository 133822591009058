
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const subTitle = "NIAIS STUDENT SUCCESS STORY REVIEW";
const title = "Students Testimonial.";

const instructorBottomList = [
    {
        imgUrl: 'https://www.youtube.com/embed/3k_M66-V0DU?si=B-cSAotkuCXlx4D-',
       
    },
    {
        imgUrl: "https://www.youtube.com/embed/rMKbsNZ6LLc?si=QuB6kz8QSnM8bJZE"
    },
    {
        imgUrl: "https://www.youtube.com/embed/bs_pf1KmNcA?si=XwTX5ROYu5jOetnD"
    },
    {
        imgUrl: "https://www.youtube.com/embed/AxMrilGHLtk?si=DQsRp3GXmKZU_ifn"
    },
    {
        imgUrl: "https://www.youtube.com/embed/QZVqdZ2VClE?si=n0aRTD6yrOIZvGHJ"
    },
    {
        imgUrl: "https://www.youtube.com/embed/qhm0jyQKk88?si=K7nWvAD-NBvNwOMB"
    }
    
]

const InstructorThree = () => {
    return (
        <div className="instructor-section style-3 padding-tb section-bg-ash">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                  
                    <div className="instructor-bottom">
                        <div className="instructor-slider overflow-hidden">
                            <div className="instructor-navi instructor-slider-next"><i className="icofont-rounded-double-right"></i></div>
                            <div className="instructor-navi instructor-slider-prev"><i className="icofont-rounded-double-left"></i></div>
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2}
                                loop={'true'}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: true,
                                }}
                                navigation={{
                                    prevEl: '.instructor-slider-prev',
                                    nextEl: '.instructor-slider-next',
                                }}
                                modules={[Autoplay, Navigation]}
                                breakpoints={{
                                    0: {
                                        width: 0,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        width: 768,
                                        slidesPerView: 3,
                                    },
                                    1200: {
                                        width: 1200,
                                        slidesPerView: 5.7,
                                    },
                                }}
                            >
                                {instructorBottomList.map((val, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="instructor-item">
                                            <div className="instructor-inner">
                                                <div className="instructor-thumb">
                                                    <iframe height={'320'} width={'100%'} src={val.imgUrl} title={i} key={i}  ></iframe>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default InstructorThree;