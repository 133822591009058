import React, { Fragment,Component } from 'react';
import { Container, Row, Col,Collapse,Button } from 'react-bootstrap';

import { Link } from "react-router-dom";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import Select from "react-select";
import { RegisterBreadcrumb } from '../component/common/RegisterBreadcrumb';
import axios from 'axios';
import swal from 'sweetalert'
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigates } from '../component/helper/useNavigates'; // Import the custom hook
import HeaderSeven from '../component/layout/header-7';

const title = "Course Registration";

const btnText = "Submit";




class CourseRegistration extends Component {


    constructor(props) {
       
        super(props)
        this.state = {
            domains: [],
            collapse:[],
            courses:[],
            loading:false,
            
        }
       
        this.getData = this.getData.bind(this)
        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.addOrRemove = this.addOrRemove.bind(this)
        this.addtoCart = this.addtoCart.bind(this)
        
        
    }

     toggleCollapse = index => {
        let collapseCopy = [...this.state.collapse];
        collapseCopy[index] = !collapseCopy[index];
       this.setState({collapse:collapseCopy});
    }
  
         getData = async () =>{
            const domains = await axios.get(global.config.URL.api.public + 'domain-courses');
            this.setState({domains:domains.data.domains});
        }

         addOrRemove = (id) => {
            const newCourses = [...this.state.courses];
            const index = newCourses.indexOf(id);
            if (index === -1) {
              newCourses.push(id);
            } else {
              newCourses.splice(index, 1);
            }
            if(newCourses.length > 2)
            {
               
                swal("Warning!", "You can take max 2 courses!", "warning");
                document.getElementById(id).checked = false;
            }else{
                this.setState({courses:newCourses},function(){
                    console.log(this.state.courses,'ok')
                });
            }
          }
        componentDidMount()
        {
            
            this.getData();
        }

         addtoCart = async () =>{
            if(this.state.courses.length === 0)
            {
                swal("Warning!", "Please Select Course", "warning");
            }else if(this.state.courses.length > 2)
            {
                swal("Warning!", "You can take max 2 courses!", "warning");

            }else{
                if(!global.config.Token){
                    window.location.href="/login"
                }
                this.setState({loading:true})
                axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${global.config.Token}`;        
                const res = await axios.post(global.config.URL.api.student + 'course-registration',{
                    courses :this.state.courses
                  },);
                  if(res && res.data.success)
                  {
                    window.location.href="/my-cart"
                   }else{
                    this.setState({loading:false})
                   swal("Warning!", res.data.message, "warning");
                   }
                }
            }

    render() {
    return (
        <Fragment>
            <HeaderThree />
           
            <div className="login-section  pb-5 section-bg">
            <RegisterBreadcrumb active={3}  className="pt-5"/>
                <div className="container ">
                    <div className="account-wrapper" style={{ maxWidth:'700px' }}>
                        <h3 className="title">{title}</h3>
                        
                        <Row>
                            <Col md="12">
                        
                                     
                                     {
                                       this.state.domains.map((domain, i) => (
                                    
                                       
                                    <Collapse in={ true}>
                                        <div id={domain.id+"-collapse-text"}>
                                       
                                        <table className='table course-tbl' >
                                            <tr>
                                                <th>Action</th>
                                                <th>Course Name</th>
                                                <th>Fee</th>
                                                </tr>
                                               {  domain.courses.map((course, j) => (

                                               
                                                <tr>
                                                    <td>
                                                    <input  type="checkbox" onClick={() => this.addOrRemove(course.id)} id={course.id}  className='check-box'/>
                                                    </td>
                                                    <td htmlFor={course.id}><b>{course.name}</b></td>
                                                    <td><del className='text-danger'> {course.discount_fee}</del> {course.fee}</td>
                                                   
                                                    </tr>
                                                     ))
                                                    }
                                        </table>
                                     
                                        </div>
                                    </Collapse>
                                      
                                        ))
                                      }
                                       {
                                            this.state.loading ? <button disabled className="text-center"><InfinitySpin  width='100' color="#ffb200" /></button> :   <div className="form-group"><button className="lab-btn text-light" onClick={() => this.addtoCart()}>Submit</button></div> 
                                        }
                                       
                                  
                             
                            </Col>
                        </Row>
                       
                    </div>
                </div>
            </div>
            <FooterTwo />
        </Fragment>
    )
}
}

 
export default CourseRegistration;