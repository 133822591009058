import React, { useEffect,useState,Fragment } from 'react';
import { Link } from "react-router-dom";
import HeaderThree from "../component/layout/header-3";
import FooterTwo from "../component/layout/footer-2";
import Select from "react-select";
import { RegisterBreadcrumb } from '../component/common/RegisterBreadcrumb';
import axios from 'axios';
import swal from 'sweetalert'
import { Helmet } from 'react-helmet';
const title = "Profile Creation";

const btnText = "Submit";



const ProfileSignup = () => {
    const [qualifications, setQualifications]= useState([]);
    const [cities, setCities]= useState([]);
   
 
    useEffect(() => {
        const form = document.getElementById("form_registration");
        const cnic = document.getElementById("registration_cnic");
       
       
        const wa = document.getElementById("registration_wa");
     
       
        const address = document.getElementById("registration_address");
       
        // Validation
        var isCNIC=false;
        var isQUA=false;
        var isWA=false;
        var isCITY=false;
        var isADDRESS=false;

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const cnicValue = cnic.value.trim();
            const qua =  document.getElementsByName("qua")[0];
            const waValue = wa.value.trim();
            const city =   document.getElementsByName("city")[0];
            const addressValue = address.value.trim();
           
            if (cnicValue === "") {
                setError(cnic, "CNIC can't be blank");
            } else if(!isNIC(cnicValue) || cnicValue.length !==13)
            {
                setError(cnic, "CNIC must be 13 digits");
            } else {
                isCNIC = true;
                setSuccess(cnic);
            }
            if (qua.value === "") {
              
                setError(qua, "Please Select Qualification");
            } else {
                isQUA = true;
                setSuccess(qua);
            }
            if (waValue === "") {
                setError(wa, "Whatsapp can't be blank");
            }
            else if(!isMobile(waValue) || waValue.length !==11)
            {
                setError(wa, "WhatsApp Number must be 11 digits");
            }
            else {
                isWA = true;
                setSuccess(wa);
            }
            if (city.value === "") {
                setError(city, "Please Select City");
            } else {
                isCITY = true;
                setSuccess(city);
            }
            if (addressValue === "") {
                setError(address, "Address can't be blank");
            } else {
                isADDRESS = true;
                setSuccess(address);
            }

            if(isCNIC && isQUA && isWA && isCITY && isADDRESS )
            {
               ProfileRegister();
            }
        }

        function setError(input, message) {
            input.focus();
            if((input.name === "qua") ||  (input.name === "city"))
            {
                const formControl = document.getElementById(input.name);
                 const errorMsg = formControl.querySelector(".registration_input-msg");
                 formControl.className = "form-group text-danger text-start";
                 errorMsg.innerText = message;
               
     
            }
            else{
                const formControl = input.parentElement;
                 const errorMsg = formControl.querySelector(".registration_input-msg");
                 formControl.className = "form-group text-danger text-start";
                 errorMsg.innerText = message;
     
            }
           
          
        }

        function setSuccess(input) {
           
            if((input.name === "qua") ||  (input.name === "city"))

            {
              const formControl = document.getElementById(input.name);
              const errorMsg = formControl.querySelector(".registration_input-msg");
             
              errorMsg.innerText = "";
             
    
            }
            else{

                const  formControl = input.parentElement;
                formControl.className = "form-control success";
            }
            console.log(input,'inputsus')
          
            
           
        }

    
        function isMobile(mobile) {
            return mobile.match('[0-9]{11}');
        }
        function isNIC(cnic) {
            return cnic.match('[0-9]{13}');
        }
        
      
        EducationCities();
        const ProfileRegister = async () =>{
          
            axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${global.config.Token}`;
          
           
            const res = await axios.post(global.config.URL.api.student + 'profile-register',{
               cnic: cnic.value.trim(),
               whatsapp: wa.value.trim(),
               qualification: document.getElementsByName("qua")[0].value,
               city: document.getElementsByName("city")[0].value,
               address: address.value.trim(),
             },);
             if(res && res.data.success)
             {
                 const auth = JSON.parse(localStorage.getItem("authUser"))
                 auth.profile = true;
                 localStorage.setItem("authUser", JSON.stringify(auth));
                 swal({
                    title: "Registered!",
                    text: "Your profile has been successfully registered!",
                    icon: "success",
                    button: "OK!",
                  }).then(() => {
                    window.location.href = '/course-registration';
                    });
             }else{
              const message = res.data.message;
              if(message.cnic)
              {
               setError(cnic,  message.cnic);
              }
              if(message.whatsapp)
              {
               setError(wa,  message.whatsapp);
              }
             
              if(message.address)
              {
               setError(address,  message.address);
              }
              
        }
       }   
    },[]);
    const EducationCities = async () =>{
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
       
        const res = await axios.get(global.config.URL.api.student + 'education-cities');
       if(res.data.success)
       {
          setCities(res.data.cities);
          setQualifications(res.data.qualifications);
       }
    }

    const SelectBox1 = ({ options, name,id, onChange }) => {
        const [quaSelected, setSelectedQua] = useState([{ value: '', label: 'Qualification' }]);
  
        const handleChange = (selected) => {
          onChange({ name,id, category: selected.value });
            
         
          setSelectedQua(selected);


        };
      
        return (
          <Select
            options={options}
            isLoading={!options}
            closeMenuOnSelect={true}
            onChange={handleChange}
            value={quaSelected}
            name={name}
         
          />
        );
      };

      const SelectBox2 = ({ options, name,id, onChange }) => {
        const [optionSelected, setSelectedOption] = useState([{ value: '', label: 'City' }]);
      
      
        const handleChange = (selected) => {
          onChange({ name,id, category: selected.value });
           
           setSelectedOption(selected);


        };
      
        return (
          <Select
            options={options}
            isLoading={!options}
            closeMenuOnSelect={true}
            onChange={handleChange}
            value={optionSelected}
            name={name}
           
          />
        );
      };
      const handleChange = (e) => {
        console.log(e);
      };
    
      //const [data, setData] = useState([]);
      /* get data */
    
     
      //console.log(data);
      const Allqualifications = qualifications.map((item) => ({ value: item.id, label: item.name + `(${item.parent.name})` }));
      const Allcities = cities.map((item) => ({ value: item.id, label: item.name  }));
    return (
        <Fragment>

            <HeaderThree />
           
            <div className="login-section  pb-5 section-bg">
            <RegisterBreadcrumb active={1}/>
                <div className="container ">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form" id="form_registration">
                            <div className="form-group">
                            <input type="text" placeholder="Enter CNIC" id="registration_cnic" />
                                            <span className="registration_input-msg"></span>
                            </div>
                            <div className="form-group"  id="qua">
                                
                            <SelectBox1
                                                options={Allqualifications}
                                                name={"qua"}
                                                id={"select1"}
                                                onChange={handleChange}
                                                />
                                           
                                            <span className="registration_input-msg"></span>
                            </div>
                            <div className="form-group">
                            <input type="text" placeholder="Enter Whatsapp Number"  id="registration_wa" />
                                            <span className="registration_input-msg"></span>
                            </div>
                            <div className="form-group" id="city">
                            <SelectBox2
                                                options={Allcities}
                                                name={"city"}
                                                id={"select2"}
                                                onChange={handleChange}
                                                />
                                            {/* <input type="text" placeholder="Enter Whatsapp Number" value={'Lahore'} id="registration_city" /> */}
                                            <span className="registration_input-msg"></span>
                            </div>
                            <div className="form-group">
                            <input type="text" placeholder="Enter your address" id="registration_address" />
                                            <span className="registration_input-msg"></span>
                            </div>
                            <div className="form-group">
                                <button className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                       
                    </div>
                </div>
            </div>
            <FooterTwo />
        </Fragment>
    );
}
 
export default ProfileSignup;